import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../app-config.service';

@Injectable({
    providedIn: 'root'
})

export class GeneraItuService {

    private URL = '';
    private URLREINVOICE = '';

    constructor(private http: HttpClient, private configService: AppConfigService) {
        this.URL = this.configService.getConfig().urlServiceInvoice;
        this.URLREINVOICE = this.configService.getConfig().urlServiceReinvoice;
    }

    validateItu(obj) {
        obj.company = this.configService.getConfig().companyName;
        console.log('new object in request for Invoice /validateItu: ', obj);
        return this.http.post(`${this.URL}/validateItu`, obj);
    }

    saveItus(obj) {
        console.log('new object in request Invoice /saveItus ->', obj);
        return this.http.post(`${this.URL}/saveItus`, obj);
    }

    releaseItus(obj){
        obj.company = this.configService.getConfig().companyName;
        console.log('new object in request Invoice /deleteItus ->', obj);
        return this.http.post(`${this.URL}/deleteItus`, obj);
    }

    validateItuReinvoice(obj) {
        obj.company = this.configService.getConfig().companyName;
        console.log('new object in request REinvoice /validateItu', obj);
        return this.http.post(`${this.URLREINVOICE}/validateItu`, obj);
    }

    cancelReinvoice(obj) {
        obj.company = this.configService.getConfig().companyName;
        console.log('new object in request REinvoice /releaseItu', obj);
        return this.http.post(`${this.URLREINVOICE}/releaseItu`, obj);
    }

    getClientReinvoice(obj) {
        console.log('new object in request REinvoice /getClientData ->', obj);
        return this.http.post(`${this.URLREINVOICE}/getClientData`, obj);
    }
}
