import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { MatPaginatorIntl } from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainNavComponent } from './main-nav/main-nav.component';

import { HomeComponent } from './home/home.component';
import { GeneraFComponent } from './genera-f/genera-f.component';
import { GuiaFComponent } from './guia-f/guia-f.component';

import { CustomMaterialModule } from './custom-material/custom-material.component';
import { ConfirmationDialogComponent } from './commons/confirmation-dialog/confirmation-dialog.component';

import { IngresaRfcComponent } from './genera-f/ingresa-rfc/ingresa-rfc.component';
import { PorRfcComponent } from './consulta-f/por-rfc/por-rfc.component';
import { PorItuComponent } from './consulta-f/por-itu/por-itu.component';
import { FooterComponent } from './commons/footer/footer.component';
import { CustomPaginator } from './commons/custom-paginator/custom-paginador';
import { DialogOverviewDialogComponent } from './commons/modal/dialog-overview-dialog/dialog-overview-dialog.component';
import { FormInvoiceComponent } from './genera-f/form-invoice/form-invoice.component';
import { SheetOverviewComponent } from './commons/sheet-overview/sheet-overview.component';
import { PoliticasComponent } from './assets/html/politicasHuawei.component';

// Import library module
import { NgxSpinnerModule } from 'ngx-spinner';
import { TableItuComponent } from './consulta-f/por-itu/table-itu/table-itu.component';
import { TableRfcComponent } from './consulta-f/por-rfc/table-rfc/table-rfc.component';

import { IngresaDatosComponent } from './refacturacion/ingresa-datos/ingresa-datos.component';

import { ReinvoiceInputItuComponent } from './reinvoice/reinvoice-input-itu/reinvoice-input-itu.component';
import { ReinvoiceEditDataComponent } from './reinvoice/reinvoice-edit-data/reinvoice-edit-data.component';

import { GuideReinvoiceComponent } from './guides/guide-reinvoice/guide-reinvoice.component';
import { GuideInvoiceComponent } from './guides/guide-invoice/guide-invoice.component';
import { GuideConsultComponent } from './guides/guide-consult/guide-consult.component';
import { FaqComponent } from './guides/faq/faq.component';
import { AppConfigService } from './app-config.service';

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    GeneraFComponent,
    GuiaFComponent,
    HomeComponent,
    ConfirmationDialogComponent,
    IngresaRfcComponent,
    PorRfcComponent,
    PorItuComponent,
    FooterComponent,
    DialogOverviewDialogComponent,
    FormInvoiceComponent,
    SheetOverviewComponent,
    TableItuComponent,
    TableRfcComponent,
    IngresaDatosComponent,
    ReinvoiceInputItuComponent,
    ReinvoiceEditDataComponent,
    GuideReinvoiceComponent, 
    GuideInvoiceComponent,
    GuideConsultComponent,
    FaqComponent,
    PoliticasComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    PdfViewerModule,
    CustomMaterialModule,
    NgbModule,
    NgxSpinnerModule
  ],
  providers: [
    DatePipe,
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginator
    },
    AppConfigService,
    {
      provide: APP_INITIALIZER, 
      useFactory: appInitializerFn,
      multi: true,
      deps:[AppConfigService]
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationDialogComponent, DialogOverviewDialogComponent, SheetOverviewComponent],
})
export class AppModule { }
